<template>
  <a-select
    show-search
    allowClear
    :required="true"
    :disabled="disabled"
    :filter-option="filterOption"
    :placeholder="$t(`请选择供应商`)"
    style="width: 100%"
    v-model="index"
    @change="supplierIndexChange"
  >
    <a-select-option :key="item.supplierCode" :value="index" v-for="(item, index) in supplierList">
      {{ item.supplierName }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'selectSupplier',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    code: {},
  },
  data() {
    return {
      supplierList: [],
      index: undefined,
    }
  },
  created() {
    this.queryOrderList()
  },
  watch: {
    code: {
      handler() {
        this.getDefault()
      },
      immediate: true,
    },
    supplierList: {
      handler(val) {
        val.forEach((item, index) => {
          if (item.supplierCode == this.code) {
            this.index = index
          }
        })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clear() {
      this.index = undefined
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    getDefault() {
      if (this.supplierList.length === 0) return
      let index = this.supplierList.findIndex((item) => item.supplierCode === this.code)
      if (index > -1) {
        this.supplierIndexChange(index)
        this.index = index
      } else {
        this.supplierIndexChange()
        this.index = undefined
      }
    },
    supplierIndexChange(val) {
      let data = this.supplierList[val] || {}
      let formData = {
        ...data,
        supplierAddress: data.fullAddress,
        supplierMobile: data.mobile,
        openingBank: data.bankName,
      }
      this.$emit('supplierIndexChange', formData)
    },
    queryOrderList() {
      this.$nextTick(() => {
        let data = {
          status: 1,
        }
        http({
          type: 'post',
          url: api.queryPurchaseSupplierList,
          data,
          success: (res) => {
            this.supplierList = convertKeysToCamelCase(res.result.list)
            if (this.code) {
              this.getDefault()
            }
          },
        })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
