<template>
  <div>
    <a-button type="primary" :disabled="disabled" :loading="loading" @click="pushHandle">
      {{ type === 'PayableOrder' ? $t('下推') : $t('下推付款单') }}
    </a-button>

    <a-modal :title="$t('下推')" v-model="nextVisible" @cancel="nextVisible = false">
      <a-form-model>
        <a-form-model-item :label="$t('单据类型')" required>
          <a-select v-model="nextType" style="width: 100%">
            <a-select-option value="paymentRequest">{{ $t('付款申请单') }}</a-select-option>
            <a-select-option value="paymentNote">{{ $t('付款单') }}</a-select-option>
            <a-select-option
              v-if="type === 'PayableOrder' && selectedRows.length === 1"
              value="payableOrderAdjust"
            >
              {{ $t('应付调整单') }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <span class="text-note" v-if="type === 'PayableOrder'">
        {{ $t('应付单下推会过滤应付单中的预付单，如果需要下推预付单，请去预付单列表操作') }}
      </span>
      <template slot="footer">
        <a-button @click="nextVisible = false">{{ $t('取消') }}</a-button>
        <a-button type="primary" @click="pushNextHandle">{{ $t('确定') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'PayableOrder', //默认是应付单PayableOrder下推 采购申请单PaymentRequest 预付单DepositPayment
    },
  },
  methods: {
    pushHandle() {
      //判断selectedRows里面的供应商是不是同一个
      let supplierList = this.selectedRows.map((item) => item.supplierCode)
      let supplierSet = new Set(supplierList)
      if (supplierSet.size > 1) {
        this.$message.warning(this.$t('请选择同一个供应商的订单'))
        return
      }
      if (this.type === 'PayableOrder') {
        this.nextVisible = true
        this.nextType = 'paymentRequest'
      } else {
        this.nextType = 'paymentNote'
        this.pushNextHandle()
      }
    },
    pushNextHandle() {
      if (this.nextType === 'payableOrderAdjust') {
        this.$emit('pushAdjust', this.selectedRows)
        return
      }
      //过滤预售的单子
      let pushList = this.selectedRows
      if (this.type === 'PayableOrder') {
        pushList = pushList.map((item) => {
          item.planList = item.planList.filter((elem) => !elem.isPrepay)
          return item
        })
      }
      localStorage.setItem('referDocType', this.type)
      localStorage.setItem('pushPayableList', JSON.stringify(pushList))
      this.$router.push({
        name: this.nextType + 'Detail',
      })
      this.nextVisible = false
    },
  },
  data() {
    return {
      loading: false,
      nextVisible: false,
      nextType: 'paymentRequest',
    }
  },
}
</script>

<style lang="less" scoped>
.text-note {
  color: #999;
  font-size: 12px;
}
</style>
