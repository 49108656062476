<template>
  <div>
    <a-button type="primary" :disabled="disabled" @click="showModal">
      {{ $t('生成凭证') }}
    </a-button>

    <a-modal :title="$t('生成凭证')" v-model="visible" @cancel="visible = false">
      <a-form-model>
        <a-form-model-item :label="$t('生成方式')" required v-if="selectedRows.length > 1">
          <a-radio-group v-model="generateType" style="width: 100%">
            <a-radio value="one">{{ $t('单个生成') }}</a-radio>
            <a-radio value="all">{{ $t('汇总生成') }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :label="$t('凭证模板')" required>
          <a-select v-model="templateId" style="width: 100%">
            <a-select-option v-for="item in templateList" :key="item.id" :value="item.id">
              {{ item.template_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="visible = false">{{ $t('取消') }}</a-button>
        <a-button type="primary" :loading="loading" @click="clickHandle">
          {{ $t('确定') }}
        </a-button>
      </template>
    </a-modal>

    <a-modal
      width="800px"
      :title="$t('生成凭证结果')"
      v-model="resultVisible"
      @cancel="cancelHandle"
      :footer="null"
    >
      <template v-if="errorMessageList.length > 0">
        <div class="infoTitle">
          <div class="lineLeft"></div>
          {{ $t('错误信息') }}
        </div>
        <CommonTable
          :columns="resultColumns"
          :dataSource="errorMessageList"
          :showPagination="false"
        >
          <template v-slot:msg="{ text }">
            <span class="text-danger">{{ text }}</span>
          </template>
        </CommonTable>
      </template>
      <template v-if="voucherList.length > 0">
        <div class="infoTitle mt20">
          <div class="lineLeft"></div>
          {{ $t('生成凭证结果') }}
        </div>
        <CommonTable :columns="voucherColumns" :dataSource="voucherList" :showPagination="false">
          <template v-slot:voucher_sn="{ text, record }">
            <span class="text-link" @click="goToVoucherDetail(record)">{{ text }}</span>
          </template>
        </CommonTable>
      </template>
    </a-modal>
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
export default {
  components: { CommonTable },
  props: {
    docType: {
      type: String,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      templateList: [],
      generateType: 'one',
      templateId: undefined,
      resultVisible: false,
      errorMessageList: [],
      voucherList: [],
    }
  },
  computed: {
    resultColumns() {
      return [
        {
          title: this.$t('单据号'),
          dataIndex: 'doc_sn',
          width: 300,
        },
        {
          title: this.$t('结果'),
          dataIndex: 'msg',
          scopedSlots: {
            customRender: 'msg',
          },
        },
      ]
    },
    voucherColumns() {
      return [
        {
          title: this.$t('单据号'),
          dataIndex: 'doc_sn_list',
          width: 300,
          customRender: (text) => {
            return text.join(',')
          },
        },
        {
          title: this.$t('凭证单据号'),
          dataIndex: 'voucher_sn',
          scopedSlots: {
            customRender: 'voucher_sn',
          },
        },
      ]
    },
  },
  methods: {
    getVoucherTemplateList() {
      if (this.templateList.length > 0) return
      http({
        url: api.getVoucherTemplateList,
        data: {
          doc: this.docType,
        },
        success: (res) => {
          let list = res.result.list
          this.templateList = list
          if (list.length === 1) {
            this.templateId = list[0].id
          }
        },
      })
    },
    showModal() {
      this.visible = true
      this.getVoucherTemplateList()
    },
    async clickHandle() {
      this.loading = true
      try {
        if (this.generateType === 'one') {
          let results = await Promise.allSettled(
            this.selectedRows.map((row) => this.generateVoucherHandle([row.id]))
          )
          console.log('results', results) // 处理每个结果
          let errorMessageList = [],
            voucherList = []
          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              // this.$message.error(item.reason)
              let orderItem = this.selectedRows[index]
              errorMessageList.push({
                doc_sn: orderItem.paymentNoteSn || orderItem.orderSn,
                msg: item.reason,
              })
              return
            }
            let { error_message_list, voucher_list } = item.value.result
            if (error_message_list?.length > 0) {
              errorMessageList.push(...error_message_list)
            }
            if (voucher_list?.length > 0) {
              voucherList.push(...voucher_list)
            }
          })
          this.handleVoucherResult({
            error_message_list: errorMessageList,
            voucher_list: voucherList,
          })
        } else if (this.generateType === 'all') {
          const docIds = this.selectedRows.map((row) => row.id)
          let { result } = await this.generateVoucherHandle(docIds)
          console.log('result', result)
          this.handleVoucherResult(result)
        }
        // this.$message.success(this.$t('凭证生成成功'))
        this.visible = false
        this.$emit('success')
      } catch (error) {
        this.$message.error(error || this.$t('凭证生成失败'))
      } finally {
        this.loading = false
      }
    },
    async generateVoucherHandle(docIds) {
      try {
        let res = await http({
          url: api.generateVoucher,
          data: {
            docIds,
            docType: this.docType,
            templateId: this.templateId,
          },
          fail: () => {},
        })
        return Promise.resolve(res)
      } catch (err) {
        return Promise.reject(err.msg)
      }
    },
    handleVoucherResult(result) {
      let { error_message_list, voucher_list } = result
      if (error_message_list.length > 0 || voucher_list.length > 0) {
        this.resultVisible = true
        this.errorMessageList = error_message_list
        this.voucherList = voucher_list
      }
    },
    goToVoucherDetail(record) {
      console.log(record)
    },
    cancelHandle() {
      this.$emit('close')
    },
  },
}
</script>
